import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import facebook from "../images/icons-footer/facebook-circular-logo.svg";
// import twitter from "../images/icons-footer/x.svg";
// import instagram from "../images/icons-footer/instagram.svg";
// import tiktok from "../images/icons-footer/tik-tok.svg";
import ResponsiveImage from "./Img/ResponsiveImage";

const Footer = () => {
  const { i18n, t } = useTranslation();
  const date = new Date();
  const year = date.getFullYear();
  return (
    <FooterDiv>
      <div>
        <div>
          <a href="/privacy-policy" rel="nofollow">
            {t("footer-a-o")} |{" "}
          </a>
          <a href="/terms-of-use" rel="nofollow">
            {t("footer-a-t")} |{" "}
          </a>
          <a
            href="https://sendy.playground.ca/subscription?f=bJP1wJRJSHeeook12I5763q892qJVbLJRYjV7Thf16xNsnqezld7Z9X86GCHf97POAlokj28Dck0F8923jec9cfFMEEA"
            target="__blank"
            rel="nofollow"
          >
            {t("footer-a-three")}
          </a>
        </div>
      </div>
      {i18n.language === "en" ? (
        <div>&#169; {year} Babel® Lounge. All right reserved.</div>
      ) : (
        <div>&#169; {year}, Babel® Lounge. Tous droits réservés.</div>
      )}
      <div>
        <a
          href="https://www.facebook.com/profile.php?id=61550994097839"
          target="__blank"
        >
          <ResponsiveImage
            src={"svg/facebook-circular-logo.svg"}
            alt="facebook-icon"
            fluidImage={true}
            customStyles={{
              width: "24px",
              height: "24px",
              objectFit: "contain",
              opacity: 0.7,
              transition: "0.1s all",
              "&:hover": {
                opacity: 1,
                transition: "0.1s all",
              },
            }}
          />
        </a>
        <a href="https://www.instagram.com/babel.lounge/" target="__blank">
          <ResponsiveImage
            src={"svg/instagram.svg"}
            alt="instagram-icon"
            fluidImage={true}
            customStyles={{
              width: "24px",
              height: "24px",
              objectFit: "contain",
              opacity: 0.7,
              transition: "0.1s all",
              "&:hover": {
                opacity: 1,
                transition: "0.1s all",
              },
            }}
          />
        </a>
        {/* <a href="https://twitter.com/Babel_Lounge" target="__blank">
          <img src={twitter} alt="twitter-icon" />
        </a> */}
        <a href="https://www.tiktok.com/@babel_lounge" target="__blank">
          <ResponsiveImage
            src={"svg/tik-tok.svg"}
            alt="tiktok-icon"
            fluidImage={true}
            customStyles={{
              width: "24px",
              height: "24px",
              objectFit: "contain",
              opacity: 0.7,
              transition: "0.1s all",
              "&:hover": {
                opacity: 1,
                transition: "0.1s all",
              },
            }}
          />
        </a>
      </div>
    </FooterDiv>
  );
};
const FooterDiv = styled.div`
  position: absolute;
  bottom: 0;
  padding: 15px 40px;
  height: auto;
  width: 100vw;
  overflow: hidden;
  color: var(--silver);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  z-index: 1;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    & > div {
      & > a {
        &:hover {
          color: inherit;
          transition: 0.1s all;
          color: var(--white);
        }
      }
    }
    & > p {
      margin-bottom: 10px;
      &:hover {
        display: none;
        color: white;
      }
    }
  }
  & > div:first-child {
    justify-content: flex-start;
  }
  & > div:last-child {
    justify-content: flex-end;
  }
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    flex-direction: column;
    position: relative;
    padding: 40px 20px;
    & > div {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      width: 100%;
      & > div {
        flex-direction: column;
        gap: 20px;
      }
    }
    & > div:last-child {
      flex-direction: row;
      justify-content: center;
    }
  }
`;
export default Footer;
